<template>
  <div class="q-pa-md">
    <products-search
            v-if="isSearching"
            :disabled="true"
            :products="orderProductsUpdated"
            :options="option"
            :default-open="openOffersSearch"
            @on-search="handleSearch"
            @on-close-search="closeOffersSearch"
            @submit="handleSubmit"
            @on-item-change="handleItemChange"
    >
    </products-search>
    <q-card>
      <q-card-section class="row border-bottom items-center full-width q-py-xs q-pl-none">
        <q-legend
            :label="$t('Order Products')"
            text-class="text-h6"
        />

        <search
            dense
            autoset
            is-expandable
            @submit="handleSearch"
        />

        <q-space/>

        <q-btn
            :color="serverParams.filter && serverParams.filter.length > 0 ? 'light-blue-9' : 'dark'"
            text-color="white"
            size="sm"
            class="q-mr-sm"
            :label="filterBtnText"
            no-caps
            unelevated
            @click="openCloseFilters"
        />

        <q-btn
            color="dark"
            text-color="white"
            :label="$t('Refresh')"
            size="sm"
            class="q-mr-sm"
            no-caps
            unelevated
            @click="refreshItems"
        />

      </q-card-section>

      <q-card-section class="q-ma-none q-pa-none">
        <filters-collapse
            :is-open="isOpenFilters"
            :options="{
              defaultFilter: serverParams.filter,
              fields: activatedFields,
              values: {
              }
            }"
            @submit="handleFiltersSubmit"
            @close="openCloseFilters"
        />
      </q-card-section>

      <q-card-section class="q-pa-none">
        <q-table
            style="height: calc(100vh - 130px);"
            class="sticky-header-table"
            row-key="id"
            :rows-per-page-label="$t('Rows per page')"
            :rows="items"
            :columns="columns"
            v-model:pagination="pagination"
            :loading="isLoading"
            :filter="filter"
            :rows-per-page-options="[25, 50, 100, 150, 200, 250]"
            virtual-scroll
            binary-state-sort
            flat
            @request="onRequest"
        >
          <template v-slot:loading>
            <q-inner-loading
                showing
                color="primary"
            />
          </template>

          <template v-slot:body="props">
              <order-products-table
                  :data="props"
                  @open="handleShowInfo"
                  @search-offer="handleOfferSearch"
                  @product-change="orderChange"
              />
          </template>

        </q-table>
      </q-card-section>
    </q-card>
  </div>
  <offer-modal ref="offerModal" @submit="handleOfferSubmit"/>
</template>

<script>
import Search from "@/apps/app/components/search/Search.vue";
import TableMixin from "@/apps/app/components/global/TableMixin.vue";
import {buildQuery} from "@/apps/app/utils/query-utils";
import FiltersCollapse from "@/apps/app/components/filters/FilterCollapse.vue";
import OrderProductsTable from "@/apps/app/components/tables/OrderProductsTable.vue";
import OfferModal from "@/apps/app/components/modals/OfferModal.vue";
import {mapActions} from "vuex";
import ProductsSearch from "@/apps/app/components/products/ProductsSearch.vue";

export default {
  name: 'OrderProducts',
  components: {
    ProductsSearch,
    OfferModal,
    OrderProductsTable,
    FiltersCollapse,
    Search,
  },
  mixins: [
    TableMixin
  ],
  data () {
    return {
      filter: '',
      items: [],
      isSearching: false,
      totalItems: '',
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      isOpenFilters: false,
      isLoading: false,
      option: {},
      acceptance: {},
      orderProductsUpdated: [],
      updatedItems: {},
      columns: [
        {
          label: this.$t('Image'),
          name: 'image',
          align: 'left'
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left'
        },
        {
          label: this.$t('Order'),
          name: 'order',
          align: 'left',
        },
        {
          label: this.$t('Store'),
          name: 'shop',
          align: 'left'
        },
        {
          label: this.$t('Product'),
          name: 'offer',
          align: 'left'
        },
        {
          label: this.$t('Count'),
          name: 'count',
          align: 'left'
        },
        {
          label: this.$t('Dimensions'),
          name: 'dimensions',
          align: 'left'
        },
        {
          label: this.$t('Price'),
          name: 'discount',
          align: 'center'
        },
        {
          label: this.$t('Total'),
          name: 'total',
          align: 'center'
        }
      ],
      activatedFields: [
        'id',
        'orderId',
        'state',
        'shop',
        'productOffer==i'
      ],
    }
  },
  mounted () {
    this.loadDefaultItems()
  },
  computed: {
    filterBtnText () {
      return this.serverParams.filter && this.serverParams.filter.length > 0
        ? this.$t('Filtered: ') + this.totalItems
        : this.$t('Filter')
    },
  },
  methods: {
    ...mapActions([
      'loadOrder',
      'loadShop'
    ]),
    loadDefaultItems () {
      return this.onRequest({ pagination: {} })
    },
    openCloseFilters () {
      this.isOpenFilters = !this.isOpenFilters
    },
    refreshItems () {
      return this.onRequest({
        pagination: {
          forceReload: true
        }
      })
    },
    handleSearch (search) {
      return this.onRequest({ pagination: { page: 1, search } })
    },
    handleFiltersSubmit (filter) {
      this.isOpenFilter = false
      return this.onRequest({ pagination: { filter, page: 1 } })
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)

      if (query.search && query.search[query.search.length - 1] !== '*' && query.search[query.search.length - 2] !== ':' && !query.search.includes('%')) {
        query.search += query.search[query.search.length - 1] === ':'
          ? '*'
          : ':*'
      }
      this.isLoading = true
      this.updateParams(query)
      return this.$service.order.getProducts(this.serverParams)
        .then(({ items, page, totalItems }) => {
          this.pagination = {
            ...this.pagination,
            page,
            rowsNumber: totalItems
          }
          this.items = items
          this.totalItems = totalItems
        })
        .finally(()=>{
          this.isLoading = false
        })
    },
    handleShowInfo (item) {
      if (item._embedded.productOffer._embedded.virtualShop) {
        const shopID = item._embedded.productOffer._embedded.virtualShop._links.self.href.split('/').pop()

        const options = {
          warning: this.$t('All changes won\'t be affected on the already existing documents and received products!')
        }

        this.loadShop(shopID)
          .then(e => {
            const product = {
              ...item,
              ...item._embedded.productOffer,
              _embedded: {
                shop: e
              }
            }
            this.$refs.offerModal.open(product, options)
          })
      } else {
        const shopID = item._embedded.productOffer._embedded.shop._links.self.href.split('/').pop()

        const options = {
          warning: this.$t('All changes won\'t be affected on the already existing documents and received products!')
        }

        this.loadShop(shopID)
          .then(e => {
            const product = {
              ...item,
              ...item._embedded.productOffer,
              _embedded: {
                shop: e
              }
            }
            this.$refs.offerModal.open(product, options)
          })
      }
    },
    handleOfferSubmit (productOffer) {

      this.items = this.items.map(x => {
        if (x._embedded.productOffer && `${x._embedded.productOffer.id}` === `${productOffer.id}`) {
          return {
            ...x,
            _embedded: {
              ...x._embedded,
              productOffer
            }
          }
        }

        return x
      })
    },
    options () {
      return {
        onlyOneShop: true,
        allWarehouses: true,
        disabledWarehouse: this.order && this.order.id,
        filter: this.order && this.order.type === 'bundle'
          ? [{ type: 'eq', field: 'type', value: 'bundle' }]
          : [],
        defaultValues: {
          shop: this.order && this.order._embedded.shop
        }
      }
    },
    handleOfferSearch (item) {
      this.openOffersSearch = true

      this.options(this.order, item)

    },
    orderChange (order) {
      return this.$service.deliveryOrder.get(order.id)
        .then(result => {
          this.order = result.order
          this.getTheItems()
          this.options(this.order)
          this.isSearching = true
        })

    },
    getTheItems (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)

      if (!query.filter) {
        query.filter = []
      }
      query.filter.push({ field: 'order', type: 'eq', value: this.order.id })

      if (this.order?._embedded?.shop) {
        query.filter.push({ field: 'shop', type: 'eq', value: this.order._embedded.shop.id })
      }

      return this.$service.order.getProducts(query)
        .then(({ items, totalPages, page, totalItems }) => {

          if (!this.updatedItems[page - 1]) {
            this.updatedItems[page - 1] = []
          }

          this.updatedItems[page - 1] = [...this.updatedItems[page - 1], ...this.orderProductsUpdated.filter(x => x.isUpdated)]


          this.orderProductsUpdated = this.updatedItems[page]

            ? items.map(x => {
              const updatedItem = this.updatedItems[page].find(i => i.id === x.id && i.originalSku === x.originalSku)
              return updatedItem || x
            })
            : items

          return { items, totalPages, page, totalItems }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  }
}
</script>