<template>
  <div v-if="offer">
    <action-header
        :is-loading="isLoading"
        :actions-model="headerModel"
        :page="page"
    />

    <component
        :is="pages[offer.type] || pages.default"
        :mode="mode"
        :disabled="!hasBaseAccess"
        :is-loading="isLoading"
        @change="handleChange"
    />

    <offer-products-table
        v-if="offer && productTypes.includes(offer.type)"
        ref="productsTable"
        :offer="offer"
        @change="handleChange"
        @price="handlePrice"
    />

    <div
        v-if="options.warning"
        class="text-center q-pa-sm rounded bg-warning q-mx-md q-mb-sm"
    >
      {{ options.warning }}
    </div>

    <div v-if="mode === 'modal'" class="text-center q-pa-xs border-top">
      <q-btn
          color="dark"
          text-color="white"
          size="sm"
          :label="$t('Back')"
          class="q-mr-sm"
          no-caps
          unelevated
          @click="handleBack"
      />

      <q-btn
          color="light-blue-9"
          text-color="white"
          size="sm"
          :label="$t('Save')"
          no-caps
          unelevated
          @click="save"
      />
    </div>

    <sticky-bottom-header
        :is-loading="isSaveLoading"
        :is-active="mode !== 'modal' && hasChange"
        @back="handleDiscard"
        @save="save"
    />

    <eav-modal ref="eavModal" @submit="handleEAVSubmit">
      <form-builder :schema="schema"/>
    </eav-modal>

    <location-logs-modal ref="logs"/>

    <items-info-modal ref="itemsInfo"/>

    <products-statistics-table-modal ref="statisticsTable"/>

    <tasks-patch-modal ref="tasksModal" @submit="handleSubmit"/>

    <document-template-modal
        ref="documentModal"
        :entity-class="'Orderadmin\\Products\\Entity\\Product\\Offer'"
    />

    <print-offer-barcodes-quantity-modal ref="printQuantity"/>
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Components
import PalletProduct from './PalletProduct.vue'
import GroupedOffer from './GroupedOffer.vue'
import Offer from './Offer.vue'
import ActionHeader from '../../components/action-header/ActionHeader.vue'
import ItemsInfoModal from '../../components/modals/ItemsInfoModal.vue'
import LocationLogsModal from '../../components/modals/LocationLogsModal.vue'
import EavModal from '../../components/modals/EavModal.vue'
import OfferProductsTable from '../../components/products/OfferProductsTable.vue'
import TasksPatchModal from '../../components/modals/TasksPatchModal.vue'
import DocumentTemplateModal from '../../components/modals/DocumentTemplateModal.vue'

// Services
import { InstructionsService } from '../../services/instructions.service'
import ProductsStatisticsTableModal from '@/apps/app/components/modals/ProductsStatisticsTableModal.vue'
import PrintQuantityModal from '@/apps/app/components/modals/PrintQuantityModal.vue'
import moment from 'moment'
import PrintOfferBarcodesQuantityModal from '@/apps/app/components/modals/PrintOfferBarcodesQuantityModal.vue'

export default {
  name: 'Product',
  emits: ['submit', 'close'],
  props: {
    options: {
      type: Object,
      default () {
        return {}
      }
    },
    mode: {
      type: String,
      default () {
        return null
      }
    }
  },
  components: {
    PrintOfferBarcodesQuantityModal,
    PrintQuantityModal,
    ProductsStatisticsTableModal,
    PalletProduct,
    Offer,
    GroupedOffer,
    ActionHeader,
    ItemsInfoModal,
    LocationLogsModal,
    EavModal,
    OfferProductsTable,
    TasksPatchModal,
    DocumentTemplateModal
  },
  data () {
    return {
      isSaveLoading: false,
      productTypes: ['bundle', 'grouped'],
      instructionsService: null,
      hasChange: false,
      isLoading: false,
      types: [
        'simple',
        'grouped',
        'bundle'
      ],
      pages: {
        grouped: 'grouped-offer',
        bundle: 'offer',
        default: 'offer'
      },
      productsOfferBundleEav: [],
      totalCount: 0
    }

  },
  computed: {
    ...mapGetters([
      'appOptions',
      'offer',
      'hasBaseAccess'
    ]),
    page () {
      return {
        id: this.offer && this.offer.id,
        name: this.offer && this.offer.id
            ? this.$t('Product')
            : this.$t('New product')
      }
    },
    schema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                value: this.offer.extId,
                label: this.$t('Ext ID'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                onChange: (extId) => {
                  this.updateOffer({ extId })
                }
              }
            ]
          }
        ]
      }
    },
    headerModel () {
      if (this.isLoading) {
        return []
      }

      const id = this.mode === 'modal'
          ? (this.offer || {}).id
          : this.$route.params.id

      return [
        {
          section: 'BackAction',
          className: 'col-sm-1 hide-on-mobile',
          options: [
            {
              id: 'back',
              type: 'button',
              icon: 'arrow_back',
              variant: 'light',
              style: 'white-space: nowrap;',
              label: this.$t('Back'),
              onClick: this.handleBack
            }
          ]
        },
        {
          section: 'Title',
          className: 'col-sm-2 mobile-title q-pl-sm',
          options: [
            {
              id: 'title',
              type: 'text',
              value: id ? this.$t('Product ') + id : this.$t('New product'),
              additionalValue: this.offer && `${this.$t('Type')}: ${this.$t(this.offer.type)}`
            }
          ]
        },
        {
          section: 'Actions',
          className: 'col-sm-6 row text-center row justify-center',
          options: [
            {
              id: 'reserves',
              wrapperClassName: 'q-px-xs',
              wrapperStyle: 'max-width: 120px;',
              type: 'button',
              disabled: !this.offer || !this.offer.id,
              variant: 'light',
              label: this.$t('Reserves'),
              onClick: () => {
                const data = { productOffer: this.offer.id, state: 'blocked' }
                const groups = [
                  { field: 'productOffer', alias: 'i' },
                  { field: 'warehouse', alias: 'i' },
                  { field: 'place', alias: 'i' },
                  { field: 'reserve', alias: 'i' }
                ]
                const options = { title: 'Reserves' }

                const query = {
                  per_page: 250,
                  page: 1,
                  filter: [
                    { type: 'eq', field: 'state', value: 'active' }
                  ]
                }

                if (this.offer._embedded.virtualShop?._embedded?.owner) {
                  query.filter.push({
                    type: 'eq',
                    field: 'owner',
                    value: this.offer._embedded.virtualShop._embedded.owner.id
                  })
                } else if (this.offer._embedded.shop?._embedded?.owner) {
                  query.filter.push({ type: 'eq', field: 'owner', value: this.offer._embedded.shop._embedded.owner.id })
                } else {
                  this.loadShop(this.offer._embedded.shop.id)
                      .then(e => {
                        this.offer._embedded.shop = e
                        query.filter.push({
                          type: 'eq',
                          field: 'owner',
                          value: this.offer._embedded.shop._embedded.owner.id
                        })
                      })
                }

                this.$service.warehouse.getAll(query)
                    .then(e => {
                      const result = e.items.map(el => el.id)
                      data.warehouse = result
                      this.$refs.itemsInfo.open(data, groups, options)
                    })

              }
            },
            {
              id: 'locations',
              wrapperClassName: 'q-px-xs',
              wrapperStyle: 'max-width: 120px;',
              type: 'button',
              icon: 'place',
              hasIcon: true,
              disabled: !this.offer || !this.offer.id,
              variant: 'light',
              onClick: () => {
                const data = { productOffer: this.offer.id }
                const groups = [
                  { field: 'productOffer', alias: 'i' },
                  { field: 'warehouse', alias: 'i' },
                  { field: 'place', alias: 'i' },
                  { field: 'reserve', alias: 'i' }
                ]
                const options = {
                  title: 'Locations',
                  filter: [
                    { type: 'neq', field: 'state', value: 'shipped' }
                  ]
                }

                const query = {
                  per_page: 250,
                  page: 1,
                  filter: [
                    { type: 'eq', field: 'state', value: 'active' }
                  ]
                }

                if (this.offer?._embedded?.virtualShop?._embedded) {
                  if (this.offer._embedded.virtualShop?._embedded?.owner.id) {
                    query.filter.push({
                      type: 'eq',
                      field: 'owner',
                      value: this.offer._embedded.virtualShop._embedded.owner.id
                    })
                    this.$service.warehouse.getAll(query)
                        .then(e => {
                          const result = e.items.map(el => el.id)
                          data.warehouse = result
                          this.$refs.itemsInfo.open(data, groups, options)
                        })
                  }
                } else if (this.offer?._embedded?.shop?._embedded) {
                  if (this.offer._embedded.shop?._embedded?.owner.id) {
                    query.filter.push({
                      type: 'eq',
                      field: 'owner',
                      value: this.offer._embedded.shop._embedded.owner.id
                    })
                    this.$service.warehouse.getAll(query)
                        .then(e => {
                          const result = e.items.map(el => el.id)
                          data.warehouse = result
                          this.$refs.itemsInfo.open(data, groups, options)
                        })
                  }
                } else {
                  this.loadShop(this.offer._embedded.shop.id)
                      .then(e => {
                        this.offer._embedded.shop = e
                        query.filter.push({ type: 'eq', field: 'owner', value: e._embedded.owner.id })
                        this.$service.warehouse.getAll(query)
                            .then(e => {
                              const result = e.items.map(el => el.id)
                              data.warehouse = result
                              this.$refs.itemsInfo.open(data, groups, options)
                            })
                      })
                }

              }
            },
            {
              id: 'locationslogs',
              wrapperClassName: 'q-px-xs',
              type: 'button',
              icon: 'history',
              hasIcon: true,
              disabled: !this.offer || !this.offer.id,
              variant: 'light',
              onClick: () => {
                this.$refs.logs.open(this.offer)
              }
            },
            {
              id: 'statistics',
              wrapperClassName: 'q-px-xs',
              type: 'button',
              label: this.$t('Statistics'),
              disabled: !this.offer || !this.offer.id,
              hasIcon: true,
              variant: 'light',
              onClick: () => {
                this.$refs.statisticsTable.open(this.offer, this.offer._embedded.shop)
              }
            },
            {
              id: 'eav',
              wrapperClassName: 'q-px-xs',
              type: 'button',
              label: this.$t('EAV'),
              hasIcon: true,
              variant: 'light',
              onClick: () => {
                const options = {
                  entityClass: this.$entities.Orderadmin_Products_Entity_Product_Offer
                }

                this.$refs.eavModal.open(this.offer.eav, options)
              }
            },
            {
              id: 'print',
              type: 'dropdown',
              wrapperClassName: 'q-px-xs',
              icon: 'qr_code',
              label: this.$t('Print label'),
              options: [
                {
                  id: 'default',
                  type: 'button',
                  label: this.$t('Default'),
                  disabled: !this.offer || !this.offer.id,
                  onClick: () => {
                    this.startLoading()
                    this.$service.offer.print(this.offer._embedded.shop.id, this.offer.id)
                        .then(({ text }) => {
                          this.$service.printer.print(text, 'LABEL')
                        })
                        .finally(() => {
                          this.stopLoading()
                        })
                  }
                },
                {
                  id: 'new',
                  label: this.$t('Print barcodes'),
                  disabled: !this.offer || !this.offer.id,
                  onClick: () => {
                    // const formattedDate = moment(this.acceptance?.created.date).format('MM/DD/YYYY')
                    //
                    this.$refs.printQuantity.open(this.offer)
                    //
                    // const barcode = this.getBarcode(this.offer)
                    //
                    // let label = ''
                    //
                    // if (this.offer.sku) {
                    //   label += this.offer.sku
                    // }
                    //
                    // if (this.offer.name) {
                    //   label += ` \n ${this.offer.name}`
                    // }
                    //
                    // if (this.offer.article) {
                    //   label += ` \n ${this.offer.article}`
                    // }
                    //
                    // this.instructionsService.labelPrint({
                    //   data: {
                    //     size: '30x30',
                    //     fontSize: 5,
                    //     barcode: `${barcode}`,
                    //     label,
                    //     forceNoAutoClose: true
                    //   }
                    // })
                  }
                },
                {
                  id: 'template',
                  icon: 'receipt_long',
                  disabled: !this.offer || !this.offer.id,
                  label: this.$t('Documents'),
                  onClick: this.handleGetDocument
                }
              ]
            },
            {
              if: this.offer.type === 'simple' && this.appOptions.versionType !== 'simple',
              id: 'copy',
              wrapperClassName: 'q-px-xs',
              wrapperStyle: 'max-width: 120px;',
              type: 'button',
              label: this.$t('Extend to bundle'),
              disabled: !this.offer || !this.offer.id,
              variant: 'light',
              onClick: () => {
                this.handleAddComponent()
              }
            },
            {
              if: this.offer.type === 'bundle',
              id: 'copy',
              wrapperClassName: 'q-px-xs',
              wrapperStyle: 'max-width: 120px;',
              type: 'button',
              label: this.$t('Add component'),
              disabled: !this.offer || !this.offer.id,
              variant: 'light',
              onClick: () => {
                this.handleAddComponent()
              }
            }
            // {
            //   id: 'print',
            //   wrapperClassName: 'q-px-xs',
            //   wrapperStyle: 'max-width: 150px;',
            //   type: 'button',
            //   icon: 'qr_code',
            //   disabled: !this.offer || !this.offer.id,
            //   variant: 'light',
            //   label: this.$t('Print label'),
            //   onClick: () => {
            //     // this.startLoading()
            //     // this.$service.offer.print(this.offer._embedded.shop.id, this.offer.id)
            //     //   .then(({ text }) => {
            //     //     this.$service.printer.print(text, 'LABEL')
            //     //   })
            //     //   .finally(() => {
            //     //     this.stopLoading()
            //     //   })
            //     const barcode = this.getBarcode(this.offer)

            //     let label = ''

            //     if (this.offer.sku) {
            //       label += this.offer.sku
            //     }

            //     if (this.offer.name) {
            //       label += ` \n ${this.offer.name}`
            //     }

            //     if (this.offer.article) {
            //       label += ` \n ${this.offer.article}`
            //     }

            //     this.instructionsService.labelPrint({
            //       data: {
            //         size: '30x30',
            //         fontSize: 5,
            //         barcode: `${barcode}`,
            //         noTitle: true,
            //         label,
            //         forceNoAutoClose: true
            //       }
            //     })
            //   }
            // }
          ]
        }
      ]
    }
  },
  beforeMount () {
    this.instructionsService = new InstructionsService(this.$refs, this.$service.printer, (...params) => fetch(...params))

    if (this.mode === 'modal') {
      if (!this.offer) {
        this.setNewOffer()

        if (this.options.type && this.types.includes(this.options.type)) {
          this.updateOffer({ type: this.options.type })
        }

        if (this.options.shop) {
          this.updateOfferEmbedded({ shop: this.options.shop })
        }

        this.loadDefaultShop()
      }

      if (!this.offer.dimensions) {
        this.setDefaultDimensions()
      }

      return
    }

    if (!this.$route.params.id) {
      this.setNewOffer()

      if (this.types.includes(this.$route.params.type)) {
        this.updateOffer({ type: this.$route.params.type })
      }

      this.loadDefaultShop()
      return
    }

    this.isLoading = true
    return this.loadOffer(this.$route.params)
        .then(() => {
          if (!this.offer.dimensions) {
            this.setDefaultDimensions()
          }

          if (this.offer && this.offer.eav && Object.keys(this.offer.eav).length > 0) {

          } else {
            this.getEntityDataProduct()
          }
        })
        .finally(() => {
          this.isLoading = false
        })
  },
  unmounted () {
    this.setOffer(null)
  },
  methods: {
    ...mapMutations([
      'setOffer',
      'updateOffer',
      'upsertOffer',
      'setNewOffer',
      'updateOfferEmbedded',
      'startLoading',
      'stopLoading',
      'addErrorNotification'
    ]),
    ...mapActions([
      'loadOffer',
      'saveOffer',
      'loadShop'
    ]),
    getEntityData (url) {
      this.$service.httpClient.get(url).then(data => {
        this.offer.eav = data.eav
      })
    },
    getEntityDataProduct () {
      let productURL = this.offer._links.self.href.split('api')[1]
      let url
      if (productURL.split('offer')[1].split('/').length > 2) {
        url = productURL
      } else {
        url = `${productURL.split('offer')[0]}offer/${this.offer._embedded.shop.id}${productURL.split('offer')[1]}`
      }
      this.getEntityData(url)
    },
    setDefaultDimensions () {
      this.updateOffer({ dimensions: { x: 0, y: 0, z: 0 } })
    },
    handlePrice (purchasingPrice) {
      this.updateOffer({ purchasingPrice })
    },
    handleAddComponent () {
      let update = {}

      if (this.offer.eav?.['products-offer-bundle'] && Array.isArray(this.offer.eav['products-offer-bundle'])) {
        if (this.offer.type !== 'bundle') {
          update['type'] = 'bundle'
        }

      } else {
        update['type'] = 'bundle'
        update['purchasingPrice'] = this.offer.purchasingPrice

        if (this.offer.eav) {
          update['eav'] = {
            ...(this.offer.eav),
            'products-offer-bundle': []
          }
        } else {
          update['eav'] = { 'products-offer-bundle': [] }
        }
      }

      this.updateOffer(update)
      this.hasChange = true
    },
    handleSubmit () {
      if (!this.$route.params.id) {
        this.handleBack()
      }
    }
    ,
    handleEAVSubmit (eav) {
      this.updateOffer({ eav })
      this.hasChange = true
    }
    ,
    handleGetDocument () {
      this.$refs.documentModal.open(this.offer)
    }
    ,
    loadDefaultShop () {
      const query = {
        per_page: 1,
        page: 1,
        filter: [
          { field: 'state', type: 'in', values: ['active', 'blocked'] }
        ]
      }

      this.$service.shop.getAll(query)
          .then(({ items, totalItems }) => {
            if (totalItems === 1) {
              this.updateOffer({
                _embedded: {
                  ...this.offer._embedded,
                  shop: items[0]
                }
              })
            }
          })
    }
    ,
    getBarcode (offer) {
      if (!offer.barcodes || typeof offer.barcodes === 'string' || !offer.barcodes[0]) {
        return `S/I/PO${this.offer.id}*`
      }

      return offer.barcodes[0]
    }
    ,
    save () {
      this.isSaveLoading = true

      if (this.offer.type !== 'simple') {
        if (this.$refs.productsTable?.getItems() && this.hasNewProduct(this.$refs.productsTable?.getItems())) {
          this.$refs.productsTable?.getItems().forEach(offer => {
            this.totalCount += parseInt(offer.count, 10)
          })

          if (this.totalCount < 2) {
            this.hasChange = false
            this.isSaveLoading = false
            return this.addErrorNotification('Minimum quantity is in bundle order is 2.')
          } else {
            const copyOffer = {
              article: this.offer.article,
              eans: this.offer.eans,
              upcs: this.offer.upcs,
              weight: this.offer.weight,
              dimensions: this.offer.dimensions,
              image: this.offer.image,
              name: `Single || ${this.offer.name}`,
              price: this.offer.price,
              purchasingPrice: this.offer.purchasingPrice,
              shop: this.offer._embedded.shop.id,
              state: this.offer.state,
              type: 'simple',
            }

            this.$service.offer.save(copyOffer)
                .then(e => {
                  let update = {}

                  if (this.offer.type !== 'bundle') {
                    update['type'] = 'bundle'
                  }
                  update['purchasingPrice'] = this.offer.purchasingPrice

                  const items = this.$refs.productsTable.getItems()

                  this.productsOfferBundleEav = items
                  this.productsOfferBundleEav.forEach((item) => {
                    if (!item.productOffer) {
                      item.productOffer = e.id
                    }
                  });

                  update['eav'] = { 'products-offer-bundle': this.productsOfferBundleEav }

                  this.updateOffer(update)
                  this.$emit('change', this.offer)

                  this.saveOffer()
                      .then(item => {
                        this.upsertOffer(item)
                        this.hasChange = false

                        if (this.mode === 'modal') {
                          this.$emit('submit', item)
                          return
                        }

                        if (!this.$route.params.id) {
                          this.handleBack()
                        }
                      })
                      .finally(() => {
                        this.isSaveLoading = false
                      })
                })
          }
        } else {
          if (this.offer.eav) {
            this.updateOffer({
              eav: {
                ...(this.offer.eav),
                'products-offer-bundle': this.$refs.productsTable.getItems()
              }
            })
            this.$emit('change', this.offer)
          } else {
            this.updateOffer({
              eav: {
                'products-offer-bundle': this.$refs.productsTable.getItems()
              }
            })
            this.$emit('change', this.offer)
          }

          this.saveOffer()
              .then(item => {
                this.upsertOffer(item)
                this.hasChange = false

                if (this.mode === 'modal') {
                  this.$emit('submit', item)
                  return
                }

                if (!this.$route.params.id) {
                  this.handleBack()
                }
              })
              .finally(() => {
                this.isSaveLoading = false
              })
        }
      } else {
        this.saveOffer()
            .then(item => {
              this.upsertOffer(item)
              this.hasChange = false

              if (this.mode === 'modal') {
                this.$emit('submit', item)
                return
              }

              if (!this.$route.params.id) {
                this.handleBack()
              }
            })
            .finally(() => {
              this.isSaveLoading = false
            })
      }
    },
    handleDiscard () {
      if (this.mode === 'modal') {
        this.$emit('close')
        return
      }

      this.$router.go()
    },
    handleBack () {
      if (this.mode === 'modal') {
        this.$emit('close')
        return
      }

      this.$router.back()
    },
    handleChange () {
      this.hasChange = true
    },
    hasNewProduct (items) {
      return items.some(item => !item.productOffer)
    }
   }
}
</script>
