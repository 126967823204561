<template>
  <q-card>
    <div class="border-bottom q-pa-sm">
      <products-search
          :products="items"
          :options="options"
          :disabled="disabled"
          @on-search="handleSearch"
          @submit="handleSubmit"
      />
    </div>

    <q-table
        row-key="id"
        class="full-width table--only-header"
        :rows-per-page-label="$t('Rows per page')"
        :rows="products"
        :columns="columns"
        v-model:pagination="pagination"
        :loading="isLoading"
        virtual-scroll
        flat
    >
      <template v-slot:body="props">
        <q-tr
            class="clickable bg-animated"
            :props="props"
            :disable="disabled"
        >
          <q-td
              key="image"
              :props="props"
              auto-width
          >
            <img
                :src="props.row._embedded.productOffer.image || fallbackImage"
                style="width: 60px; height: 60px; object-fit: contain;"
                @error="onImageLoadFailure"
            >
          </q-td>

          <q-td
              key="id"
              :props="props"
              class="text-subtitle1"
              auto-width
          >
            <strong>{{ props.row._embedded.productOffer.id ?? $t('New')}}</strong>
          </q-td>

          <q-td
              key="name"
              :props="props"
              style="white-space: pre-wrap !important; max-width: 50%; width: 50%"
          >
            <q-input
                standout="bg-teal text-white"
                type="text"
                :model-value="props.row._embedded.productOffer.name"
                :disable="!props.row._embedded.productOffer.id"
                :label="$t('Name')"
                @update:model-value="handleOfferSubmit(props.row._embedded.productOffer, 'name', $event)"
            />
          </q-td>

          <q-td
              key="price"
              :props="props"
          >
            {{ Number(props.row._embedded.productOffer.price || 0).toFixed(2) }}
          </q-td>

          <q-td
              key="count"
              :props="props"
              style="white-space: pre-wrap !important; max-width: 100px;"
          >
            <q-input
                standout="bg-teal text-white"
                type="number"
                :model-value="props.row.count"
                :disable="disabled"
                :label="$t('Quantity')"
                @update:model-value="handleChange(props.row, 'count', $event)"
            />
          </q-td>

          <q-td
              key="total"
              :props="props"
          >
            {{ ((props.row._embedded.productOffer.price * props.row.count) || 0).toFixed(2) }}
          </q-td>

          <q-td
              key="actions"
              :props="props"
          >
            <q-btn
                color="negative"
                icon="delete"
                :disable="disabled"
                flat
                @click="handleRemove(props.row._embedded.productOffer)"
            />
          </q-td>
        </q-tr>
      </template>
    </q-table>

    <div v-if="items.length <= 0" class="q-pa-sm text-center text-subtitle1">
      {{ $t('No products') }}
    </div>

    <div class="q-pa-sm text-center text-subtitle1 border-top">
      {{ $t('Total price') + ': ' + this.totalPrice.toFixed(2) }}
    </div>
  </q-card>
</template>

<script>
// Components
import ProductsSearch from './ProductsSearch.vue'
import { mapMutations } from 'vuex'

export default {
  name: 'OfferProductsTable',
  components: {
    ProductsSearch
  },
  props: {
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    offer: {
      type: Object,
      required: true,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      filter: '',
      fallbackImage: './assets/img/fallback-image/package.png',
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalRowsNumber: 25
      },
      updatedItems: {},
      items: [],
      isLoading: false,
      search: '',
      columns: [
        {
          name: 'image',
          label: this.$t('Image'),
          align: 'left'
        },
        {
          name: 'id',
          label: this.$t('Id'),
          align: 'left'
        },
        {
          name: 'name',
          label: this.$t('Name'),
          align: 'left'
        },
        {
          name: 'price',
          label: this.$t('Price'),
          align: 'left'
        },
        {
          name: 'count',
          label: this.$t('Quantity'),
          align: 'left'
        },
        {
          name: 'total',
          label: this.$t('Total'),
          align: 'left'
        },
        {
          name: 'actions',
          label: '',
          align: 'right'
        }
      ],
      totalPrice: 0,
      copyOffer: []
    }
  },
  computed: {
    products () {
      if (this.search) {
        return this.items.filter(x => JSON.stringify(x).toLowerCase().includes(this.search.toLowerCase()))
      }

      return this.items
    },
    options () {
      return {
        onlyOneShop: true,
        onlyOneProduct: this.offer.type === 'grouped',
        defaultValues: {
          shop: this.offer._embedded.shop
        }
      }
    }
  },
  mounted () {
    if (this.offer && this.offer.id) {
      this.loadOffers((this.offer.eav || {})['products-offer-bundle'] || [])
          .then(items => {
            this.items = items
            this.pagination.totalRowsNumber = items.length
            this.updatePrice()

            if (this.pagination.totalRowsNumber < 1) {
              this.copyOffer = {
                _embedded: {
                  productOffer: {
                    article: this.offer.article,
                    eans: this.offer.eans,
                    upcs: this.offer.upcs,
                    weight: this.offer.weight,
                    dimensions: this.offer.dimensions,
                    image: this.offer.image,
                    name: `Single || ${this.offer.name}`,
                    price: this.offer.price,
                    purchasingPrice: this.offer.purchasingPrice,
                    shop: this.offer._embedded.shop.id,
                    state: this.offer.state,
                    type: 'simple'
                  }
                }
              }

              this.addCopyOffer();
            }
          })
    }


  },
  watch: {
    offer: {
      handler (newValue) {
        this.loadOffers((this.offer.eav || {})['products-offer-bundle'] || [])
            .then(items => {
              this.items = items
              this.pagination.totalRowsNumber = items.length
              this.updatePrice()
            })
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations([
      'addWarningNotification',
    ]),
    addCopyOffer() {
      if (this.copyOffer) {
        this.copyOffer.count = 1
        this.items.unshift(this.copyOffer)
        this.copyOffer = null
      }
    },
    loadOffers (array = []) {
      if (array.length <= 0) {
        return Promise.resolve([])
      }

      const query = {
        per_page: 250,
        page: 1,
        filter: [
          { type: 'in', field: 'shop', values: array.map(x => x.shop) },
          { type: 'in', field: 'id', values: array.map(x => x.productOffer) },
        ]
      }

      this.isLoading = true
      return this.$service.offer.getAll(query)
          .then(({ items }) => {
            return items.map(x => {
              const offer = array.find(o => o.productOffer == x.id)

              x = {
                count: offer.count,
                _embedded: {
                  productOffer: x
                }
              }

              return x
            })
          })
          .finally(() => {
            this.isLoading = false
          })
    },
    getItems () {
      return this.items.map(x => {
        console.log(x)
        return {
          productOffer: x?._embedded?.productOffer?.id ? x._embedded.productOffer.id : x.id,
          name: x?._embedded?.productOffer?.name,
          count: x.count,
          shop: x._embedded?.productOffer?._embedded?.shop.id ?? x._embedded?.productOffer?.shop
        }
      })
    },
    handleSearch (search) {
      this.search = search
    },
    handleSubmit ({ shop, products }) {
      this.items = products
      this.$emit('shop-change', shop)
      this.$emit('change', this.items)
      this.updatePrice()
    },
    onImageLoadFailure (event) {
      event.target.src = this.fallbackImage
    },
    handleChange (item, key, value) {
      if (Number(value) <= 0) {
        return
      } else {
        item[key] = value
        this.updatePrice()
        this.$emit('change', this.items)
      }
    },
    handleShowInfo (offer) {
      this.$refs.offerModal.open(offer)
    },
    handleOfferSubmit (offer, key, value) {
      offer[key] = value

      let data = {}
      data[key] = value

      this.$service.offer.save(data, offer.id, offer.shop)
    },
    handleAddBundleComponent () {
      this.$emit('new-component', {})
    },
    handleRemove (row) {
      this.items = this.items.filter(x => x._embedded.productOffer.id !== row.id)
      this.$emit('change', this.items)
      this.updatePrice()
    },
    updatePrice () {
      this.totalPrice = this.items.reduce((acc, product) => {
        return acc + ((product.count || 0) * (product._embedded.productOffer.price || 0))
      }, 0)
    }
  }
}
</script>
