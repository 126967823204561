<template>
  <q-card class="cursor-pointer">
    <q-card-section class="text-caption wrap q-py-none text-center" style="align-content: center" :class="bg">
        <div class="row">
            <div class="col">
                <strong class="text-uppercase"> {{  $t(product.type) }} </strong>
            </div>

            <div>
                <q-btn-dropdown
                        size="sm"
                        flat
                        round
                        dense
                        no-icon
                        dropdown-icon="more_horiz"
                >
                    <q-list>
                        <q-item clickable v-close-popup>
                            <q-item-section @click="handleStatus(product)">
                                <q-item-label>
                                    {{ 'Hide' }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </q-btn-dropdown>
            </div>
        </div>

    </q-card-section>
    <q-img
      :src="product.image || fallbackImage"
      :alt="product.name"
      :img-style="{ height: '200px', objectFit: 'contain', maxHeight: '100%'}"
      style="height: 200px; object-fit: contain;"
      basic
      fit="contain"
      @error="onImageLoadFailure"
    >
      <div class="absolute-bottom text-subtitle1" v-if="product.name">
        <div style="max-height: 200px; overflow: auto;">
          {{ `${product.name} (${$t('ID')}: ${product.id})` }}
        </div>

        <div class="text-subtitle2">
          {{ $t('Price') + ': ' + product.price }}
        </div>
      </div>
    </q-img>

    <q-card-section class="text-caption wrap q-pb-none">
      {{ $t('Barcode') + ': ' + (product.barcodes.length ? product.barcodes.join(', ') : '-') }}
    </q-card-section>

    <q-card-section class="text-caption wrap q-py-none">
      {{ $t('Sku') + ': ' + (product.sku ? product.sku : '-') }}
    </q-card-section>


    <q-card-section class="text-center text-caption wrap overflow-auto q-pb-none">
      <items-table :items="items" />
    </q-card-section>

    <q-card-actions align="center" class="q-pt-none">
      <q-btn
        color="light-blue-9"
        size="sm"
        style="min-width: 110px;"
        icon="add_shopping_cart"
        :label="$t('Add')"
        :disable="disabled"
        @click="handleClick"
      />

<!--      <q-btn-->
<!--        color="danger"-->
<!--        text-color="white"-->
<!--        size="sm"-->
<!--        style="min-width: 110px;"-->
<!--        icon="delete"-->
<!--        :label="$t('Remove')"-->
<!--        :disable="disabled"-->
<!--        @click="handleRemove"-->
<!--      />-->
    </q-card-actions>
  </q-card>
</template>

<script>
// Components
import ItemsTable from '../items-table/ItemsTable.vue'

export default {
  name: 'ProductCard',
  emits: ['click', 'hide'],
  components: {
    ItemsTable
  },
  props: {
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    product: {
      type: Object,
      required: true,
      default () {
        return {}
      }
    },
    warehouse: {
      type: Object,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      fallbackImage: 'assets/img/fallback-image/package.png'
    }
  },
  computed: {
    items () {
      const items = this.product.items || []

      if (this.warehouse && this.warehouse.id) {
        return items.filter(x => x.warehouse == this.warehouse.id)
      }

      return items
    },
    bg () {
      if (this.product.type === 'simple') {
          return 'bg-cyan-6'
      } else if( this.product.type === 'bundle') {
          return 'bg-purple-4'
      } else {
          return 'bg-yellow-6'
      }
    },
  },
  methods: {
    handleClick () {
      this.$emit('click', this.product)
    },
    onImageLoadFailure (event) {
      event.target.src = this.fallbackImage
    },
    handleStatus () {
      this.$service.offer.save({ state: 'hidden' }, this.product.id, this.product._embedded.shop.id, {})
        .then(item => {
          // this.upsertOffer(item)
          this.$emit('hide', this.product)
        })
    },
    handleRemove () {
      this.$service.offer.save({ state: 'deleted' }, this.product.id, this.product._embedded.shop.id, {})
        .then(item => {
          // this.upsertOffer(item)
          this.$emit('hide', this.product)
        })
    }
  }
}
</script>
