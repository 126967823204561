<template>
  <q-tr
      class="clickable bg-animated"
      :class="color"
      :props="data"
      draggable="true"
      :disable="disabled"
      @dragstart="drag($event, data , place)"
      @ondragover="allowDrop($event)"
      @dblclick="handleClick(data.row)"
  >
    <q-td
        key="selected"
        :props="data"
        auto-width
        @click="handleCheck"
    >
      <q-icon
          name="check"
          size="md"
          :color="checkColor"
      />
    </q-td>

    <q-td
        key="image"
        :props="data"
        auto-width
    >
      <img
          :src="data.row.image || fallbackImage"
          style="width: 60px; height: 60px; object-fit: contain;"
          @error="onImageLoadFailure"
      >
    </q-td>

    <q-td
        key="id"
        :props="data"
        class="text-subtitle1"
        auto-width
    >
      <strong>{{ data.row.id }}</strong>
    </q-td>

    <q-td
        key="name"
        :props="data"
        style="white-space: pre-wrap !important; max-width: 200px;"
    >
      {{ data.row.name }}

      <div
          v-if="data.row.comment"
          class="q-pa-sm rounded bg-warning q-my-sm"
      >
        {{ data.row.comment }}
      </div>
    </q-td>

    <q-td
        key="payment"
        :props="data"
    >
      {{ Number(data.row.payment || 0).toFixed(2) }}
    </q-td>

    <q-td
        key="estimatedCost"
        :props="data"
    >
      {{ Number(data.row.estimatedCost || 0).toFixed(2) }}
    </q-td>

    <q-td
        key="count"
        :props="data"
    >
      {{ data.row.count }}
    </q-td>

    <q-td
        key="total"
        :props="data"
    >
      {{ ((data.row.count || 0) * (data.row.payment || 0)).toFixed(2) }}
    </q-td>

    <q-td
        key="actions"
        :props="data"
    >
      <q-btn
          color="negative"
          icon="delete"
          :disable="disabled"
          flat
          @click="openCofirmation"
      />
    </q-td>
  </q-tr>

  <q-dialog v-model="areYouSure">
    <q-card style="max-width: 30vw">
      <q-card-section class="row justify-center items-center full-height text-center text-h6">
        <div class="row justify-center items-center full-height text-center text-h6 " textarea="center">
          {{ $t('Are You Sure?') }}
        </div>

        <q-space/>

        <q-btn v-close-popup icon="close" flat round dense/>
      </q-card-section>
      <q-card-section class="row justify-center items-center full-height text-center text-h6">
        <q-card-section class="text-center">

          <q-btn
              textarea="center"
              color="negative"
              class="q-mr-sm"
              text-color="white"
              :label="$t('Delete product')"
              @click="handleRemove(data.row)"
          />

          <q-btn
              textarea="center"
              :label="$t('Discard')"
              @click="closeConfirmation"
          />
        </q-card-section>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: 'ProductRow',
  emits: ['delete', 'dblclick', 'dragstart', 'ondragover'],
  props: {
    data: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean
    },
    place: {
      type: Object
    }
  },
  data () {
    return {
      fallbackImage: './assets/img/fallback-image/package.png',
      isSelected: false,
      areYouSure: false,
    }
  },
  computed: {
    checkColor () {
      return this.isSelected
          ? 'teal'
          : 'grey'
    },
    color () {
      if (this.isSelected) {
        return this.$q.dark.isActive
            ? 'bg-dark'
            : 'bg-deep-purple-1'
      }

      return ''
    }
  },
  methods: {
    openCofirmation () {
      this.areYouSure = true
    },
    closeConfirmation () {
      this.areYouSure = false
    },
    handleCheck () {
      this.isSelected = !this.isSelected
    },
    onImageLoadFailure (event) {
      event.target.src = this.fallbackImage
    },
    handleClick (row) {
      this.$emit('dblclick', row)
    },
    handleRemove (row) {
      this.areYouSure = false
      this.$emit('delete', row)
    },
    drag (ev, data, place) {
      this.$emit('dragstart', ev, data, place)
    },
    allowDrop (ev) {
      this.$emit('ondragover', ev)
    }
  }
}
</script>
