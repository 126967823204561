<template>
  <div>
    <q-dialog
      v-model="isOpen"
      persistent
    >
      <q-card style="min-width: 40vw;">
        <q-card-section v-if="options.title">
          <div class="text-h6 text-center">
            {{ options.title }}
          </div>
        </q-card-section>

        <q-card-section>
          <div v-if="isLoading" class="text-center">
            <q-spinner
              color="primary"
              size="4rem"
              class="q-mb-md"
            />

            <div class="text-center text-subtitle1 text-bold q-py-sm">
              {{ `${count} ${$t('of')} ${totalCount}` }}
            </div>
          </div>

          <div v-if="isFinished" class="text-center text-subtitle1 text-bold q-py-sm">
            {{ `${count} ${$t('of')} ${totalCount} ${$t('are saved')}!` }}
          </div>

          <div v-if="options.description" class="text-caption text-center">
            {{ options.description }}
          </div>
        </q-card-section>

        <q-card-section class="text-center">
          <q-btn
            class="q-mr-sm"
            color="dark"
            text-color="white"
            :label="$t('Discard')"
            no-caps
            @click="cancel"
          />
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>

export default {
  name: 'TasksPatchModal',
  data () {
    return {
      isOpen: false,
      service: null,
      shouldCancel: false,
      isLoading: false,
      isFinished: false,
      options: {},
      count: 0,
      totalCount: 0
    }
  },
  methods: {
    open (service, options = {}) {
      this.isOpen = true
      this.shouldCancel = 0
      this.totalCount = (options.queue || []).length
      this.service = service
      this.isLoading = true
      return this.runQueue(options.queue, options.value, options.middlewares)
        .then(result => {
          this.$emit('submit', result)
          this.isFinished = true

          setTimeout(() => {
            this.hide()
          }, 1500)

          return result
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    runQueue (queue = [], value = {}, { onSave, onError } = {}, results = []) {
      if (queue.lengt <= 0 || this.shouldCancel) {
        return Promise.resolve(results)
      }

      const task = typeof value === 'function'
        ? value(queue[0])
        : value

      return this.service.save(task, queue[0].id)
        .then(result => {
          this.count++
          results.push(result)
          typeof onSave === 'function' && onSave(result)
          return this.runQueue(queue.slice(1), value, results)
        })
        .catch(err => {
          typeof onError === 'function' && onError(err)
        })
    },
    hide () {
      this.isOpen = false
    },
    cancel () {
      this.shouldCancel = true
    }
  }
}
</script>
